import React, { useState } from "react";
import PortableText from "react-portable-text";

const Faqs = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChangeQuestion = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="relative block box-border pb-10 show">
      <div className="relative max-w-[1140px] w-full mx-auto">
        <div className="flex flex-col flex-wrap justify-center p-[10px] sm:p-5">
          {/* Heading */}
          <div className="block w-3/4 mx-auto py-5">
            <h1 className="text-[26px] sm:text-3xl text-assent font-bold uppercase text-center mb-3 leading-10 tracking-[2px]">
              {data.heading}
            </h1>
          </div>
          {/* Buttons */}
          <div className="block w-full mx-auto text-center">
            {data.faqList?.map((item, index) => (
              <button
                key={index}
                className={`inline-block py-[2px] px-[10px] m-[6px] text-[13px]  sm:text-base uppercase rounded-[5px] text-center no-underline 
                whitespace-nowrap border border-solid border-[#ccc]
                box-border transition-all duration-200 ease-linear sm:tracking-[1px] ${
                  selectedIndex === index
                    ? "bg-primary text-white"
                    : "bg-[#cccccc] hover:text-primary"
                } `}
                onClick={() => handleChangeQuestion(index)}
                aria-label="button-4"
              >
                {item.question}
              </button>
            ))}
          </div>
          {/* Content Container */}
          {data.faqList?.map((item, index) => {
            if (selectedIndex === index)
              return (
                <div
                  className="relative w-full bg-white z-[9999] g-scaleIn"
                  style={{
                    boxShadow: "0px 0px 36px rgb(0 0 0 / 30%)",
                  }}
                  key={index}
                >
                  <div className="block p-3">
                    <div className="w-[90%] mx-auto block">
                      <div className="p-[10px]">
                        <PortableText
                          content={item._rawAnswer}
                          className="text-left text-assent text-sm sm:text-base font-bold block-content max-w-none"
                          serializers={{
                            ul: ({ children }) => (
                              <ul className="p-0 m-0 ml-3 -mt-5 list-none">
                                {children}
                              </ul>
                            ),
                            li: ({ children }) => (
                              <li className="p-0 m-0 before:content-['\2022'] before:text-assent before:font-semibold before:inline-block before:-ml-3 before:pr-1">
                                {children}
                              </li>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
